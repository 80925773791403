// Import
import Vue from 'vue';
import VueRouter from 'vue-router';
import PrivateRoutes from './PrivateRoutes.js';
import PublicRoutes from './PublicRoutes.js';
import Store from '@/store';
import { MODULE_NAME as AUTH_MODULE, GETTERS as AUTH_GETTERS } from '@/store/modules/auth/types';

// Use Vue Router
Vue.use(VueRouter);

// Start Vue Router
const router = new VueRouter({
  mode: 'history',
  routes: [...PrivateRoutes, ...PublicRoutes],
});

// Middlewares
router.beforeEach((to, from, next) => {
  // Redirect to route
  let redirectToRoute = function (name) {
    if (name === from.name) {
      next();
      return;
    }

    next({ name: name });
  };

  const isUserLoggedIn = Store.getters[`${AUTH_MODULE}/${AUTH_GETTERS.IS_LOGGED_IN}`];

  // Auth
  if (to.meta.auth) {
    if (isUserLoggedIn) {
      return next();
    } else {
      return redirectToRoute('login');
    }
  }

  // Guest
  if (to.meta.guest) {
    if (isUserLoggedIn) {
      return redirectToRoute('profile-editor');
    } else {
      return next();
    }
  }

  next();
});

// Export
export default router;
