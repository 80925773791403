import Vue from 'vue';
import App from './modules/App';
import Router from './routes';
import Store from './store';
import NotifyMixin from '@/mixins/notify';
import ToastNotification from 'vue-toastification';
import Vuelidate from 'vuelidate';
import i18n from './locales';

Vue.config.productionTip = false;
Vue.use(ToastNotification);
Vue.mixin(NotifyMixin);
Vue.use(Vuelidate);

new Vue({
  render: (h) => h(App),
  router: Router,
  store: Store,
  i18n,
}).$mount('#app');
