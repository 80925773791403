// Import Modules
const ProfileEditor = () => import('@/modules/ProfileEditor');
const MyPlan = () => import('@/modules/MyPlan');

// Export
export default [
  // Profile Editor
  {
    path: '/profile/editor',
    name: 'profile-editor',
    component: ProfileEditor,
    meta: { auth: true },
  },

  // My Plan
  {
    path: '/my-plan',
    name: 'my-plan',
    component: MyPlan,
    meta: { auth: true },
  },
];
