import axios from 'axios';
import store from '../store/index.js';
import AppConfig from '@/config/app';
import { MODULE_NAME as APP_MODULE, ACTIONS as APP_ACTIONS } from '@/store/modules/app/types';
import { MODULE_NAME as AUTH_MODULE, ACTIONS as AUTH_ACTIONS } from '@/store/modules/auth/types';
import capitalizeFirstLetter from './capitalize-first-letter';
import Router from '@/routes';

const CurrentRouteName = () => {
  return Router.currentRoute.name ?? null;
};

const ApiInstance = () => {
  const api = axios.create({
    baseURL: AppConfig.BACKEND_URL,
  });

  const handleRequest = (config) => {
    store.dispatch(`${APP_MODULE}/${APP_ACTIONS.SET_APP_LOADING}`, true);
    return config;
  };

  const handleRequestError = (error) => {
    store.dispatch(`${APP_MODULE}/${APP_ACTIONS.SET_APP_LOADING}`, false);
    return Promise.reject(error);
  };

  const handleResponse = (response) => {
    store.dispatch(`${APP_MODULE}/${APP_ACTIONS.SET_APP_LOADING}`, false);
    return response;
  };

  const handleResponseError = (error) => {
    store.dispatch(`${APP_MODULE}/${APP_ACTIONS.SET_APP_LOADING}`, false);

    const errors = error?.response?.data?.errors;
    let errorMessage = 'Oops! An error occurred. Our team is actively working to resolve the issue.';

    const statusHandlers = {
      401: () => {
        errorMessage = error.response.data.error;
      },
      404: () => {
        errorMessage = 'Page not found!';
      },
      422: () => {
        errorMessage = errors
          .map((error) => {
            return `${capitalizeFirstLetter(error.field)} - ${capitalizeFirstLetter(error.message)}.`;
          })
          .join('\n');
      },
    };

    if (error.response && error.response.status == 401 && CurrentRouteName() != 'login') {
      store.dispatch(`${AUTH_MODULE}/${AUTH_ACTIONS.SIGN_USER_OUT}`, false);
      window.location.reload();
    }

    if (error.response && statusHandlers[error.response.status]) {
      statusHandlers[error.response.status]();
    }

    store.dispatch(`${APP_MODULE}/${APP_ACTIONS.SET_AXIOS_NOTIFICATION}`, errorMessage);

    return Promise.reject(error);
  };

  api.interceptors.request.use(handleRequest, handleRequestError);

  api.interceptors.response.use(handleResponse, handleResponseError);

  return api;
};

export default ApiInstance();
