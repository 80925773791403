// Import Modules
const HomeModule = () => import('@/modules/Home');
const Error404Module = () => import('@/modules/Error404');
const LoginModule = () => import('@/modules/Login');
const LogOutModule = () => import('@/modules/LogOut');
const SignUpModule = () => import('@/modules/SignUp');
const PublicProfile = () => import('@/modules/PublicProfile');
const PrivacyPolicy = () => import('@/modules/PrivacyPolicy');
const TermsAndConditions = () => import('@/modules/TermsAndConditions');
const TermsOfService = () => import('@/modules/TermsOfService');
const RefundPolicy = () => import('@/modules/RefundPolicy');
const ForgotPasswordModule = () => import('@/modules/ForgotPassword');

// Export
export default [
  // Default route
  {
    path: '/',
    name: 'home',
    component: HomeModule,
  },

  // Error 404
  {
    path: '*',
    name: 'error-404',
    component: Error404Module,
  },
  {
    path: '/error-not-found',
    name: 'error-not-found',
    component: Error404Module,
  },

  // Login
  {
    path: '/login',
    name: 'login',
    component: LoginModule,
    meta: { guest: true },
  },

  // Logout
  {
    path: '/logout',
    name: 'logout',
    component: LogOutModule,
  },

  // Sign Up
  {
    path: '/sign-up',
    name: 'sign-up',
    component: SignUpModule,
    meta: { guest: true },
  },

  // Forgot Pw
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordModule,
    meta: { guest: true },
  },

  // Public Profile
  {
    path: '/profile/:username',
    name: 'public-profile',
    component: PublicProfile,
  },
  {
    path: '/u/:username',
    name: 'u-profile',
    component: PublicProfile,
  },

  // Terms of service
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: TermsOfService,
  },

  // Refund Policy
  {
    path: '/refund-policy',
    name: 'refund-policy',
    component: RefundPolicy,
  },

  // Privacy Policy
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },

  // Terms and Conditions
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: TermsAndConditions,
  },
];
