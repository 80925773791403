import { STATE, MUTATIONS, ACTIONS, GETTERS } from './types';

export default {
  namespaced: true,

  state: () => ({
    [STATE.USER]: localStorage.getItem([STATE.USER]),
  }),

  mutations: {
    [MUTATIONS.SET_USER](state, user) {
      state[STATE.USER] = JSON.stringify(user);
      localStorage.setItem([STATE.USER], JSON.stringify(user));
    },

    [MUTATIONS.SIGN_USER_OUT](state) {
      state[STATE.USER] = null;

      localStorage.removeItem([STATE.USER]);
    },

    [MUTATIONS.MODIFY_LOGGED_USER_USERNAME](state, modifiedUsername) {
      state[STATE.USER] = JSON.stringify({ ...JSON.parse(state[STATE.USER]), username: modifiedUsername });

      localStorage.setItem([STATE.USER], state[STATE.USER]);
    },
  },

  actions: {
    [ACTIONS.SIGN_USER_IN]({ commit }, user) {
      commit(MUTATIONS.SET_USER, user);
    },

    [ACTIONS.SIGN_USER_OUT]({ commit }) {
      commit(MUTATIONS.SIGN_USER_OUT);
    },

    [ACTIONS.UPDATE_LOGGED_USER_USERNAME]({ commit }, newUsername) {
      commit(MUTATIONS.MODIFY_LOGGED_USER_USERNAME, newUsername);
    },
  },

  getters: {
    [GETTERS.USER](state) {
      if (state[STATE.USER]) {
        return JSON.parse(state[STATE.USER]);
      }

      return null;
    },

    [GETTERS.IS_LOGGED_IN](state) {
      if (state[STATE.USER] == null || typeof state[STATE.USER] != 'string') {
        return false;
      }

      const user = JSON.parse(state[STATE.USER]);

      // Check if required fields are present
      const requiredFields = ['userId', 'expires_at', 'token', 'username'];
      if (!requiredFields.every((field) => field in user)) {
        return false;
      }

      // Check if access token expired
      if (new Date(user.expires_at) <= new Date()) {
        localStorage.removeItem([STATE.USER]);
        return false;
      }

      return true;
    },
  },
};
