// Import
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en';
import sq from './sq';

// Initialize and configure Vue with VueI18n for internationalization support
Vue.use(VueI18n);

// Create a new instance of VueI18n with configuration settings
export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: en,
    sq: sq,
  },
});
