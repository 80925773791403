import AppConfig from '@/config/app';

export default {
  methods: {
    notifyInfo(message = 'The process was completed successfully.', options = {}) {
      const notifyOptions = { timeout: AppConfig.DEFAULT_NOTIFICATION_DURATION, ...options };

      this.$toast.info(message, notifyOptions);

      this.disableAndEnableButtons();
    },

    notifySuccess(message = 'The process was completed successfully.', options = {}) {
      const notifyOptions = { timeout: AppConfig.DEFAULT_NOTIFICATION_DURATION, ...options };

      this.$toast.success(message, notifyOptions);

      this.disableAndEnableButtons();
    },

    notifyError(message = 'Something is going wrong!', options = {}) {
      const notifyOptions = { timeout: AppConfig.DEFAULT_NOTIFICATION_DURATION, ...options };

      this.$toast.error(message, notifyOptions);

      this.disableAndEnableButtons();
    },

    notifyWarning(message = 'Something is going wrong!', options = {}) {
      const notifyOptions = { timeout: AppConfig.DEFAULT_NOTIFICATION_DURATION, ...options };

      this.$toast.warning(message, notifyOptions);

      this.disableAndEnableButtons();
    },

    disableAndEnableButtons() {
      document.querySelectorAll('button').forEach((button) => {
        button.disabled = true;
      });

      setTimeout(() => {
        document.querySelectorAll('button').forEach((button) => {
          button.disabled = false;
        });
      }, AppConfig.DEFAULT_NOTIFICATION_DURATION);
    },
  },
};
