export default {
  ENVIORNMENT: process.env.NODE_ENV,

  NAME: process.env.VUE_APP_NAME || null,
  URL: process.env.VUE_APP_URL || null,
  DEFAULT_LANGUAGE: process.env.VUE_APP_DEFAULT_LANGUAGE || 'en',
  BACKEND_URL: process.env.VUE_APP_BACKEND_URL || 'http://127.0.0.1',
  BACKEND_SOCKET_URL: process.env.VUE_APP_BACKEND_SOCKET_URL || 'http://127.0.0.1:3000',
  SOCKET_ENABLED: process.env.VUE_APP_SOCKET_ENABLED || false,
  AUTHOR: 'GG Innovative',
  MAIN_EMAIL: 'info@clickprofile.com',

  DEFAULT_NOTIFICATION_DURATION: 2000,
  DEFAULT_DATE_FORMAT: 'YYYY-MM-DD',
  DEFAULT_DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',

  PADDLE_USE_SANDBOX: Boolean(process.env.VUE_APP_PADDLE_USE_SANDBOX),
  PADDLE_CLIENT_SIDE_TOKEN: process.env.VUE_APP_PADDLE_CLIENT_SIDE_TOKEN,
  PADDLE_PREMIUM_PRICE_ID: process.env.VUE_APP_PADDLE_PREMIUM_PRICE_ID,
};
