export const STATE = {
  MY_USER: 'MY_USER',
};

export const MUTATIONS = {
  MY_USER: 'MY_USER',
};

export const ACTIONS = {
  MY_USER: 'MY_USER',
  UPDATE_MY_USER: 'UPDATE_MY_USER',
  UPDATE_MY_USER_PROFILE_PICTURE: 'UPDATE_MY_USER_PROFILE_PICTURE',
  GET_USER_BY_USERNAME: 'GET_USER_BY_USERNAME',
};

export const GETTERS = {
  MY_USER: 'USER',
};

export const MODULE_NAME = 'user';
