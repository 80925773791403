export default {
  // Validation
  'validation.required': 'Kjo fushe eshte e domosdoshme.',
  'validation.minLength': 'Ju lutem vendosni te pakten {min} karaktere.',
  'validation.maxLength': 'Ju lutem vendosni jo me shume se {max} karaktere.',
  'validation.alpha': 'Ju lutem vendosni vetem karaktere alfabetike.',
  'validation.alphaNum': 'Ju lutemi vendosni vetem karaktere alfanumerike.',
  'validation.numeric': 'Ju lutemi vendosni nje vlere numerike.',
  'validation.integer': 'Ju lutemi vendosni nje vlere te plote.',
  'validation.decimal': 'Ju lutemi vendosni nje vlere decimale.',
  'validation.email': 'Ju lutemi vendosni nje adrese email valide.',
  'validation.ipAddress': 'Ju lutemi vendosni nje adrese IP valide.',
  'validation.sameAs': "Ju lutemi vendosni te njejten vlere si '{field}'.",
  'validation.url': 'Ju lutemi vendosni nje URL valide.',
  'validation.not': 'Input i pavlefshem.',
  'validation.or': 'Input i pavlefshem.',
  'validation.and': 'Input i pavlefshem.',
};
