export default {
  // Validation
  'validation.required': 'This field is required.',
  'validation.minLength': 'Please enter at least {min} characters.',
  'validation.maxLength': 'Please enter no more than {max} characters.',
  'validation.alpha': 'Please enter only alphabetic characters.',
  'validation.alphaNum': 'Please enter only alphanumeric characters.',
  'validation.numeric': 'Please enter a numeric value.',
  'validation.integer': 'Please enter an integer value.',
  'validation.decimal': 'Please enter a decimal value.',
  'validation.email': 'Please enter a valid email address.',
  'validation.ipAddress': 'Please enter a valid IP address.',
  'validation.sameAs': "Please enter the same value as '{field}'.",
  'validation.url': 'Please enter a valid URL.',
  'validation.not': 'Invalid input.',
  'validation.or': 'Invalid input.',
  'validation.and': 'Invalid input.',
};
