export const STATE = {
  AXIOS_NOTIFICATION: 'AXIOS_NOTIFICATION',
  APP_LOADING: 'APP_LOADING',
};

export const MUTATIONS = {
  CLEAR_AXIOS_NOTIFICATION: 'CLEAR_AXIOS_NOTIFICATION',
  SET_AXIOS_NOTIFICATION: 'SET_AXIOS_NOTIFICATION',
  SET_APP_LOADING: 'SET_APP_LOADING',
};

export const ACTIONS = {
  SET_AXIOS_NOTIFICATION: 'SET_AXIOS_NOTIFICATION',
  SET_APP_LOADING: 'SET_APP_LOADING',
};

export const GETTERS = {
  AXIOS_NOTIFICATION: 'GET_AXIOS_NOTIFICATION',
  APP_LOADING: 'APP_LOADING',
};

export const MODULE_NAME = 'app';
