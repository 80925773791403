export const STATE = {
  USER: 'USER',
};

export const MUTATIONS = {
  SET_USER: 'SET_USER',
  SIGN_USER_OUT: 'SIGN_USER_OUT',
  MODIFY_LOGGED_USER_USERNAME: 'MODIFY_LOGGED_USER_USERNAME',
};

export const ACTIONS = {
  SIGN_USER_IN: 'SIGN_USER_IN',
  SIGN_USER_OUT: 'SIGN_USER_OUT',
  UPDATE_LOGGED_USER_USERNAME: 'UPDATE_LOGGED_USER_USERNAME',
};

export const GETTERS = {
  USER: 'USER',
  IS_LOGGED_IN: 'IS_LOGGED_IN',
};

export const MODULE_NAME = 'auth';
