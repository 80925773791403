import { STATE, MUTATIONS, ACTIONS, GETTERS } from './types';
import AppConfig from '@/config/app';

export default {
  namespaced: true,

  state: () => ({
    [STATE.AXIOS_NOTIFICATION]: null,
    [STATE.APP_LOADING]: false,
  }),

  mutations: {
    [MUTATIONS.SET_AXIOS_NOTIFICATION](state, notification = null) {
      state[STATE.AXIOS_NOTIFICATION] = notification;
    },

    [MUTATIONS.SET_APP_LOADING](state, isLoading) {
      state[STATE.APP_LOADING] = isLoading;
    },

    [MUTATIONS.CLEAR_AXIOS_NOTIFICATION](state) {
      state[STATE.AXIOS_NOTIFICATION] = null;
    },
  },

  actions: {
    [ACTIONS.SET_AXIOS_NOTIFICATION]({ commit }, notification) {
      commit(MUTATIONS.SET_AXIOS_NOTIFICATION, notification);

      setTimeout(() => {
        commit(MUTATIONS.CLEAR_AXIOS_NOTIFICATION);
      }, AppConfig.DEFAULT_NOTIFICATION_DURATION);
    },

    [ACTIONS.SET_APP_LOADING]({ commit }, isLoading) {
      commit(MUTATIONS.SET_APP_LOADING, isLoading);
    },
  },

  getters: {
    [GETTERS.AXIOS_NOTIFICATION](state) {
      return state[STATE.AXIOS_NOTIFICATION];
    },

    [GETTERS.APP_LOADING](state) {
      return state[STATE.APP_LOADING];
    },
  },
};
