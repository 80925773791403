import Vuex from 'vuex';
import Vue from 'vue';
import AuthModule from './modules/auth/index';
import AppModule from './modules/app/index';
import UserModule from './modules/user/index';
import { MODULE_NAME as MODULE_NAME_AUTH } from '@/store/modules/auth/types';
import { MODULE_NAME as MODULE_NAME_APP } from '@/store/modules/app/types';
import { MODULE_NAME as MODULE_NAME_USER } from '@/store/modules/user/types';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    [MODULE_NAME_AUTH]: AuthModule,
    [MODULE_NAME_APP]: AppModule,
    [MODULE_NAME_USER]: UserModule,
  },
});
