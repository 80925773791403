import { ACTIONS, STATE, GETTERS, MUTATIONS } from './types';
import Api from '@/utils/api';
import UserTransformers from '@/transformers/user';

export default {
  namespaced: true,

  state: () => ({
    [STATE.MY_USER]: null,
  }),

  mutations: {
    [MUTATIONS.MY_USER](state, user) {
      state[STATE.MY_USER] = user;
    },
  },

  actions: {
    [ACTIONS.MY_USER]({ commit, rootGetters }) {
      const bearerToken = { Authorization: `Bearer ` + rootGetters['auth/USER'].token };

      return new Promise((resolve, reject) => {
        Api.get('/users/me', { headers: bearerToken })
          .then((response) => {
            commit(MUTATIONS.MY_USER, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    [ACTIONS.UPDATE_MY_USER]({ rootGetters }, user) {
      const bearerToken = { Authorization: `Bearer ` + rootGetters['auth/USER'].token };

      return new Promise((resolve, reject) => {
        Api.put('/users/me', user, { headers: bearerToken })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    [ACTIONS.UPDATE_MY_USER_PROFILE_PICTURE]({ rootGetters }, picture) {
      const formData = new FormData();
      formData.append('picture', picture);
      const bearerToken = { Authorization: `Bearer ` + rootGetters['auth/USER'].token };

      return new Promise((resolve, reject) => {
        Api.put('/users/me/picture', formData, { headers: { 'Content-Type': 'multipart/form-data', ...bearerToken } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async [ACTIONS.GET_USER_BY_USERNAME](context, username) {
      try {
        const response = await Api.get(`/users/${username}`);
        return response.data;
      } catch (error) {
        console.log(`Api error when calling ${ACTIONS.GET_USER_BY_USERNAME}`);
      }
    },
  },

  getters: {
    [GETTERS.MY_USER](state) {
      if (state[STATE.MY_USER]) {
        return UserTransformers(state[STATE.MY_USER]);
      }

      return null;
    },
  },
};
