import 'vue-toastification/dist/index.css';
import Loader from '@/components/General/Loader';
import { mapGetters } from 'vuex';
import { MODULE_NAME as APP_MODULE, GETTERS as APP_GETTERS } from '@/store/modules/app/types';

export default {
  name: 'App',

  components: {
    Loader,
  },

  computed: {
    ...mapGetters(APP_MODULE, {
      axiosNotification: APP_GETTERS.AXIOS_NOTIFICATION,
      isAppLoading: APP_GETTERS.APP_LOADING,
    }),
  },

  watch: {
    axiosNotification(message) {
      if (message) {
        this.notifyError(message);
      }
    },
  },
};
